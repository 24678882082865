/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Button, CloseButton, Divider, Flex, Grid, Group, Input, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { forwardRef, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { GoodsApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/message";
import { Form } from "../form";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { PageLayout } from "../layout";

const schema = yup.object({
  variantId: yup.string().required("Заавал бөглөнө!").nullable(),
  quantity: yup.number().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  item: any;
}

export function SupplierSalesAddDynamicAdditionalForm({ action, setFieldValue, setAction, values }: any) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [query, setQuery] = useState<string | any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const { data: variantList = [], mutate } = useSwr(
    `/erp/invoice/variant/select?query=${query}`,
    async () =>
      await GoodsApi.variantSelect({
        query: "",
        businessId: `${user?.currentBusiness?.partnerId}`,
        orderType: "SALES",
        // supplierId: "",
        categoryId: "",
        brandId: "",
      }),
    {
      fallback: [],
    },
  );

  const [data] = useState<any>({
    id: undefined,
    barCode: undefined,
    brand: undefined,
    category: undefined,
    erpCode: undefined,
    hasTax: undefined,
    hasVat: undefined,
    image: undefined,
    nameApp: undefined,
    nameBill: undefined,
    nameEng: undefined,
    nameWeb: undefined,
    price: undefined,
    priceStartDate: undefined,
    refCode: undefined,
    skuCode: undefined,
    supplier: undefined,
    taxAmount: undefined,
    unit: undefined,
    vatAmount: undefined,

    ...(action[0] === "edit" && action[1]),
  });

  const onSearchService = async (query: any) => {
    let timeout;
    if (query) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        setQuery(query);
      }, 1000);
    } else {
      setQuery("");
    }
  };

  const getVariant = async (id: string, setFieldValue: any) => {
    let res = null;

    try {
      res = variantList.filter((item: any) => item?.id === id)[0];
      setFieldValue("id", res.id);
      setFieldValue("barCode", res.barCode);
      setFieldValue("brand", res.brand);
      setFieldValue("category", res.category);
      setFieldValue("erpCode", res.erpCode);
      setFieldValue("hasTax", res.hasTax);
      setFieldValue("hasVat", res.hasVat);
      setFieldValue("image", res.image);
      setFieldValue("nameApp", res.nameApp);
      setFieldValue("nameBill", res.nameBill);
      setFieldValue("nameEng", res.nameEng);
      setFieldValue("nameWeb", res.nameWeb);
      setFieldValue("price", res.price);
      setFieldValue("priceStartDate", res.priceStartDate);
      setFieldValue("refCode", res.refCode);
      setFieldValue("skuCode", res.skuCode);
      setFieldValue("supplier", res.supplier);
      setFieldValue("taxAmount", res.taxAmount);
      setFieldValue("unit", res.unit);
      setFieldValue("vatAmount", res.vatAmount);
    } catch (error) {}
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    if (action[1]?.lines?.some((item: any) => item.id === values.id)) {
      message.error("Энэхүү бүтээгдэхүүн нэмэгдсэн байна.");
    } else {
      setFieldValue("lines", [...(action[1]?.lines || []), values]);
      setAction([]);
    }

    setLoading(false);
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, description, item, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group>
          <Avatar src={item?.image}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {item?.unit || "-"} / {currencyFormat(item?.price || 0)}
            </Text>
          </div>
        </Group>
      </div>
    );
  });

  return (
    <Form ref={ref} validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {({ setFieldValue, values }) => {
        return (
          <PageLayout title="Бараа нэмэх" subTitle="Бараа бүтээгдэхүүн нэмэх" extra={[<CloseButton onClick={() => setAction([])} key={1}></CloseButton>]}>
            <Divider color="gray" my="xs" />
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <SelectField
                  SelectItem={SelectItem}
                  searchable
                  clearable
                  name="variantId"
                  placeholder="Бараа, ажил үйлчилгээ"
                  label="Бараа, ажил үйлчилгээ"
                  onSearchChange={(e) => onSearchService(e)}
                  onChange={(e: any) => {
                    setFieldValue("quantity", undefined);
                    getVariant(e, setFieldValue);
                  }}
                  options={variantList.map((item: any) => {
                    return {
                      label: `${item.nameMon} ${item.optionValues.length > 0 ? item.optionValues.map((ov: any) => " - " + ov.name) : ""}`,
                      value: item.id,
                      item: item,
                    };
                  })}
                  required
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <NumberField min={0} name="quantity" placeholder="Тоо хэмжээ" label="Тоо хэмжээ" required disabled={!values?.variantId} />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <Input.Wrapper label={`Нэгжийн үнэ  /${values?.unit || "ширхэг"}/`}>
                  <Flex align={"center"} h="36px">
                    <Text size="sm" fw={500} c="#44566C">
                      {currencyFormat(values?.price || 0)}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <Input.Wrapper label={`Нийт үнэ`}>
                  <Flex align={"center"} h="36px">
                    <Text size="sm" fw={500} c="#44566C">
                      {currencyFormat((values?.price || 0) * (values.quantity || 0))}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
            <Divider color="gray" my="xs" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => setAction([])}>
                Буцах
              </Button>
              <Button
                loading={loading}
                disabled={!values?.variantId}
                onClick={async () => {
                  let res = await ref.current.submit();
                  if (res) onSubmit(res);
                }}>
                Хадгалах
              </Button>
            </Flex>
          </PageLayout>
        );
      }}
    </Form>
  );
}
