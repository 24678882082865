/* eslint-disable react-hooks/exhaustive-deps */
import { Button, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconListCheck, IconShoppingCart, IconTimelineEventPlus, IconTruckDelivery } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { DeliveryApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { SupplierDeliveredList } from "../../components/supplier-delivered/list";
import { SupplierDeliveryDistributeList } from "../../components/supplier-delivery-distribute/list";
import { SupplierDeliveryDistributingList } from "../../components/supplier-delivery-distributing/list";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { authDeliveryApprove } from "../../store/Auth";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export function SupplierDeliveryTabs() {
  const listRef = useRef<HTMLFormElement>(null);
  const breadcrumbs = useBreadcrumb();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<string>("DISTRIBUTE");
  const [checked, setChecked] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<any>([]);

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (query.get("success") === "true") {
      setActiveTab("DISTRIBUTING");
      query.delete("success");
      navigate(`/supplier-delivery?${query.toString()}`, { replace: true });
    }
  }, [location, navigate]);

  const onTabChange = (_index: any) => {
    setActiveTab(_index);
  };

  const approveSearch = async () => {
    setLoading(true);
    try {
      let res = await DeliveryApi.approve(
        ((action && action[1]) || [])?.map((c: IReference) => {
          return c?.id;
        }),
      );

      dispatch(authDeliveryApprove(res));
      navigate("/supplier-delivery/approve");
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (action && action[0] === "APPROVE") {
        approveSearch();
      }
    }, 400);
  }, [action]);

  const onSubmit = async () => {
    listRef?.current?.submit();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <PageLayout
      title={activeTab === "DISTRIBUTE" ? "Хүргэлт хуваарилсан захиалга" : "Хүргэхээр зөвшөөрсөн борлуулалтын захиалга"}
      subTitle="Харилцагчид хүргэхээр төлөвлөсөн буюу хүргэж дуусаагүй байгаа борлуулалтын захиалгын жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 &&
            activeTab === "DISTRIBUTE" && (
              <Button onClick={onSubmit} key={1} variant="outline" leftSection={<IconTruckDelivery />}>
                Хуваарилах
              </Button>
            )}
        </>,
        <>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_PS_CRT").length > 0 &&
            activeTab === "DISTRIBUTING" && (
              <Button
                onClick={() => setAction(["APPROVE", checked as string[]])}
                key={2}
                variant="outline"
                disabled={checked?.length === 0}
                leftSection={<IconShoppingCart />}>
                Ачилт зөвшөөрөх
              </Button>
            )}
        </>,
      ]}>
      <Paper radius="sm" p="md" withBorder h="100%" style={{ minHeight: "100%" }}>
        <Tabs defaultValue="1" value={activeTab} onChange={onTabChange}>
          <Tabs.List>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 && (
              <Tabs.Tab leftSection={<IconTruckDelivery color="#44566C" />} value="DISTRIBUTE">
                <Text size="sm" fw={500} c="#44566C">
                  Ажилтан хуваарилах
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 && (
              <Tabs.Tab leftSection={<IconTimelineEventPlus color="#44566C" />} value="DISTRIBUTING">
                <Text size="sm" fw={500} c="#44566C">
                  Ачилт зөвшөөрөх
                </Text>
              </Tabs.Tab>
            )}
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 && (
              <Tabs.Tab leftSection={<IconListCheck color="#44566C" />} value="CONFIRM">
                <Text size="sm" fw={500} c="#44566C">
                  Хүргэсэн
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 && (
            <Tabs.Panel value="DISTRIBUTE" mt="lg">
              {activeTab === "DISTRIBUTE" && <SupplierDeliveryDistributeList listRef={listRef} onSuccess={() => setActiveTab("DISTRIBUTE")} />}
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 && (
            <Tabs.Panel value="DISTRIBUTING" mt="lg">
              {activeTab === "DISTRIBUTING" && <SupplierDeliveryDistributingList checked={checked} setChecked={setChecked} />}
            </Tabs.Panel>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_LIST").length > 0 && (
            <Tabs.Panel value="CONFIRM" mt="lg">
              {activeTab === "CONFIRM" && <SupplierDeliveredList />}
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Хүргэлт удирдлага",
  },
];
