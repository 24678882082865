import { Anchor, Avatar, Badge, ColorSwatch, Divider, Flex, Grid, Group, Paper, Select, Space, Table, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  IconArrowDown,
  IconArrowUp,
  IconCaretRight,
  IconCash,
  IconDatabase,
  IconPhoto,
  IconSquareRoundedArrowDown,
  IconSquareRoundedArrowUp,
} from "@tabler/icons-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useScreenDetector } from "../../components/screen-detector";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { tugrug } from "../../utils";
import { dateFormat } from "../../utils/date";

const TableData = [
  {
    key: 1,
    name: "БАТАЛСАН",
    orderCount: 200,
    orderAmount: 15000,
    businessCount: 250,
    average: 200,
    color: "#34eb86",
  },
  {
    key: 2,
    name: "БАТАЛГААЖСАН",
    orderCount: 400,
    orderAmount: 215000,
    businessCount: 50,
    average: 1000,
    color: "#01C129",
  },
  {
    key: 3,
    name: "ТАТГАЛЗСАН",
    orderCount: 300,
    orderAmount: 3000000,
    businessCount: 250,
    average: 3000,
    color: "#FF1919",
  },
  {
    key: 4,
    name: "БЭЛТГЭЖ БАЙНА",
    orderCount: 40,
    orderAmount: 500000,
    businessCount: 50,
    average: 20,
    color: "#DAF7A6",
  },
  {
    key: 5,
    name: "ХҮРГЭЖ БАЙНА",
    orderCount: 50,
    orderAmount: 500000,
    businessCount: 150,
    average: 20,
    color: "#FED804",
  },
  {
    key: 6,
    name: "ХҮРГЭСЭН",
    orderCount: 100,
    orderAmount: 150000,
    businessCount: 250,
    average: 20,
    color: "#07ACB1",
  },
  {
    key: 7,
    name: "ДУУССАН",
    orderCount: 400,
    orderAmount: 215000,
    businessCount: 50,
    average: 1000,
    color: "#01C129",
  },
];

const delivered = [
  {
    salesCode: "SO-2012222",
    orderAmount: 20000,
    deliveredDate: "2024-03-04T03:46:20.192Z",
    receiverBusiness: {
      logo: "https://cp.dehub.mn/mdi/p/960274ee-9e62-46b2-b5b8-8559b24c829d/wallpaperflare.com_wallpaper (1).jpg",
      refCode: "PR-100025",
      profileName: "Hunnu Air",
    },
  },
  {
    salesCode: "SO-2012222",
    orderAmount: 20000,
    deliveredDate: "2024-03-04T03:46:20.192Z",
    receiverBusiness: {
      logo: "https://cp.dehub.mn/mdi/p/960274ee-9e62-46b2-b5b8-8559b24c829d/wallpaperflare.com_wallpaper (1).jpg",
      refCode: "PR-100022",
      profileName: "MIAT Mongolian Airlines",
    },
  },
  {
    salesCode: "SO-2012222",
    orderAmount: 20000,
    deliveredDate: "2024-03-04T03:46:20.192Z",
    receiverBusiness: {
      logo: "https://cp.dehub.mn/mdi/p/960274ee-9e62-46b2-b5b8-8559b24c829d/wallpaperflare.com_wallpaper (1).jpg",
      refCode: "PR-100061",
      profileName: "Newcom Group",
    },
  },
  {
    salesCode: "SO-2012222",
    orderAmount: 20000,
    deliveredDate: "2024-03-04T03:46:20.192Z",
    receiverBusiness: {
      logo: "https://cp.dehub.mn/mdi/p/960274ee-9e62-46b2-b5b8-8559b24c829d/wallpaperflare.com_wallpaper (1).jpg",
      refCode: "PR-100015",
      profileName: "Golomt Bank",
    },
  },
];

const chartData = [
  {
    name: "A ангилал",
    ordersCount: 20,
    amount: 22,
    color: "#15CAB8",
  },
  {
    name: "B ангилал",
    ordersCount: 20,
    amount: 42,
    color: "#44A6E9",
  },
  {
    name: "D ангилал",
    ordersCount: 20,
    amount: 12,
    color: "#FEC600",
  },
  {
    name: "E ангилал",
    ordersCount: 51,
    amount: 52,
    color: "#FF8548",
  },
  {
    name: "Бусад",
    ordersCount: 5,
    amount: 1,
    color: "#E2362F",
  },
];

const getOptions = () => ({
  chart: {
    zoomType: "xy",
  },
  title: {
    text: "",
    align: "left",
  },
  subtitle: {
    text: "",
    align: "left",
  },
  xAxis: [
    {
      categories: [
        "2023-10-26",
        "2023-10-27",
        "2023-10-28",
        "2023-10-29",
        "2023-10-30",
        "2023-10-31",
        "2023-11-01",
        "2023-11-02",
        "2023-11-03",
        "2023-11-04",
        "2023-11-05",
        "2023-11-06",
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      labels: {
        format: "{value}",
        style: {
          color: "gray",
        },
      },
      title: {
        text: "",
        style: {
          color: "green",
        },
      },
    },
    {
      title: {
        text: "",
        style: {
          color: "black",
        },
      },
      labels: {
        format: "{value}",
        style: {
          color: "gray",
        },
      },
      opposite: true,
    },
  ],
  tooltip: {
    shared: true,
  },
  legend: {
    align: "rigth",
    y: 18,
  },
  series: [
    {
      name: "Захиалгын дүн",
      color: "#15CAB8",
      type: "column",
      data: [50, 200, 400, 260, 50, 60, 20, 40, 99, 150, 80, 280],
      tooltip: {
        valueSuffix: "",
      },
    },
    {
      name: "Хүргэсэн дүн",
      color: "#44A6E9",
      type: "column",
      data: [23, 12, 32, 123, 132, 43, 12, 64, 34, 78, 80, 58],
      tooltip: {
        valueSuffix: "",
      },
    },
    {
      name: "Захиалгын тоо",
      type: "spline",
      color: "#FEC600",
      data: [20, 10, 30, 40, 4, 6, 2, 0, 9, 10, 0, 100],
      tooltip: {
        valueSuffix: "",
      },
    },
    {
      name: "Хүргэсэн захиалгын тоо",
      type: "spline",
      color: "#FF8548",
      data: [20, 100, 300, 240, 40, 60, 20, 40, 79, 10, 80, 180],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
  accessibility: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
});

export function DashboardStatic({ pieFilter }: { pieFilter: string }) {
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { dashboardPieFilters } = useSelector((state: { general: IGeneral }) => state.general);

  const stats = [
    { title: "БОРЛУУЛАЛТЫН ЗАХИАЛГА", value: 20000, diff: 20, key: 0, icon: IconSquareRoundedArrowDown },
    { title: "ЗАХИАЛГЫН ТОО", value: 20032, diff: 0, key: 1, icon: IconSquareRoundedArrowUp },
    { title: "ЗАХИАЛГАД БУЙ БАРАА", value: 232323, diff: -16, key: 2, icon: IconDatabase },
    { title: "ДУНДАЖ ЗАХИЛГА", value: 3821238, diff: 10, key: 1, icon: IconCash },
  ].map((stat, index) => {
    const DiffIcon = stat.diff < 0 ? IconArrowDown : IconArrowUp;

    return (
      <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }} key={index}>
        <Paper withBorder key={stat.key} radius="md">
          <Group p="sm" pb="xs">
            <stat.icon color={theme.colors.gray[6]} />
            <Text fz={"sm"} fw={600} c={theme.colors.gray[6]}>
              {stat.title}
            </Text>
          </Group>
          <Group>
            <div className={classes.grdnt}></div>
            <Space />
            <Text fz={"xl"} fw={500}>
              {tugrug(stat.value)}
            </Text>
          </Group>
          <Group align="right" p={"sm"}>
            <Text size={"xs"} c={theme.colors.gray[6]}>
              Өмнөх өдрөөс
            </Text>
            <Badge color={stat.diff < 0 ? "red" : "green"} className={stat.diff < 0 ? classes.red : classes.green}>
              <Flex align={"center"} gap={8}>
                <DiffIcon size={"0.8rem"} stroke={3} />
                <Text>
                  {stat.diff < 0 && "-"}
                  {Math.abs(stat.diff)} %
                </Text>
              </Flex>
            </Badge>
          </Group>
        </Paper>
      </Grid.Col>
    );
  });

  const getPieOptions = () => ({
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      useHTML: true,
      text: "",
      floating: true,
      verticalAlign: "middle",
      y: 30,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " ₮",
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          crop: false,
          distance: "-10%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        data: (chartData || []).map((i: any) => ({
          name: i?.name,
          y: i?.amount,
          color: i?.color,
        })),
      },
    ],
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  });

  return (
    <Grid>
      {stats}
      <Grid.Col>
        <Paper withBorder py={"xs"}>
          <Flex justify="space-between">
            <Group>
              <div className={classes.grdnt}></div>
              <Text component="h1" ml={10}>
                Борлуулалтын захиалга
              </Text>
            </Group>
          </Flex>
          <Paper px={"lg"}>
            <HighchartsReact highcharts={Highcharts} options={getOptions()} />
            <Group align="right" style={{ marginTop: 20 }}>
              <Text fz={"sm"} c="dimmed" bg={"white"} style={{ zIndex: 2 }}>
                Хэмжих нэгж: Сая төгрөгөөр
              </Text>
            </Group>
            <Space h={"xs"} />
          </Paper>
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 9 }}>
        <Paper withBorder py="xs">
          <Flex align="center" gap="sm">
            <Flex align="center" gap="sm">
              <div className={classes.grdnt}></div>
              <Text component="h1">Борлуулалтын захилга</Text>
            </Flex>
            {!isMobile ? (
              <Anchor
                size="sm"
                px="xs"
                fw={500}
                onClick={() => {
                  user?.currentBusiness?.type === "SUPPLIER" ? navigate("/supplier-sales") : navigate("/buyer-sales");
                }}>
                Бүгдийг харах
              </Anchor>
            ) : (
              <Anchor
                px="xs"
                onClick={() => {
                  user?.currentBusiness?.type === "SUPPLIER" ? navigate("/supplier-sales") : navigate("/buyer-sales");
                }}>
                <IconCaretRight />
              </Anchor>
            )}
          </Flex>
          <Paper px="lg" style={{ overflow: "auto" }}>
            <Table miw="max-content">
              <thead>
                <tr>
                  <th>Захиалгын төлөв</th>
                  <th>Захиалгын тоо</th>
                  <th>Захиалгын дүн</th>
                  <th>Харилцагчийн тоо</th>
                  <th>Дундаж дүн</th>
                </tr>
              </thead>
              <tbody>
                {(TableData || []).map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        style={{ background: "white", color: `${item?.color}`, border: `1px solid ${item?.color}` }}>
                        {item.name}
                      </Badge>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {item.orderCount}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {tugrug(item.orderAmount || 0)}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {item.businessCount}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" fw={500} c="#44566C">
                        {tugrug(item.averageAmount || 0)}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }}>
        <Paper p="sm" withBorder>
          <Text fz="lg" fw={600}>
            Сүүлд хүргэсэн
          </Text>
          <Divider my="xs" />
          <Flex direction="column" gap="sm">
            {(delivered || []).map((c, index) => {
              return (
                <Group align="apart" key={index} style={{ background: "#F8F9FA" }}>
                  <Group ml={5}>
                    <Avatar src={c?.receiverBusiness?.logo}>
                      <IconPhoto />
                    </Avatar>
                    <div>
                      <Text fw={500} c="#14182d">
                        {c?.receiverBusiness?.profileName}
                      </Text>
                      <Text c="#557d94" fw={500} size="sm">
                        {c?.receiverBusiness?.refCode}
                      </Text>
                    </div>
                  </Group>
                  <Flex justify="flex-end" align="flex-end" direction="column" wrap="nowrap" mr={5}>
                    <Text fw={500} c="#557d94">
                      {dateFormat(c?.deliveredDate)}
                    </Text>
                    <Text c="#14182d" fw={500} size="sm">
                      {tugrug(c?.orderAmount || 0)}
                    </Text>
                  </Flex>
                </Group>
              );
            })}
          </Flex>
          {user?.currentBusiness?.type === "SUPPLIER" && (
            <Group align="right" mt={5}>
              {!isMobile ? (
                <Anchor size="sm" px="xs" fw={500} onClick={() => navigate("/supplier-delivery")}>
                  Бүгдийг харах
                </Anchor>
              ) : (
                <Anchor px="xs" onClick={() => navigate("/supplier-delivery")}>
                  <IconCaretRight />
                </Anchor>
              )}
            </Group>
          )}
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 9 }}>
        <Paper withBorder py="4px">
          <Group align="apart">
            <Group align="center">
              <div className={classes.grdnt}></div>
              <Text component="h1">Борлуулалтын захилга</Text>
            </Group>
            <Select
              mr={15}
              value={pieFilter}
              data={(dashboardPieFilters || []).map((i) => {
                return {
                  label: i?.name,
                  value: i?.code,
                };
              })}
              placeholder="сонгох"
              onChange={(e) => {}}
            />
          </Group>
          <Flex justify="space-around" align="center">
            <Group w="100%">
              <HighchartsReact highcharts={Highcharts} options={getPieOptions()} />
            </Group>
            <Flex direction="column" gap="sm" w="100%" mr={15}>
              {(chartData || []).map((c, index) => {
                return (
                  <Group key={index} align="start" ml={40}>
                    <Group ml={5}>
                      <ColorSwatch color={c.color} size={6} />
                      <div>
                        <Text fw={500} c="#14182d">
                          {c?.name || "-"}
                        </Text>
                        <Text c="#557d94" fw={500} size="sm">
                          {c?.ordersCount}
                        </Text>
                      </div>
                    </Group>
                    <Flex mr={5}>
                      <Text fw={500} c="#557d94">
                        {tugrug(c?.amount || 0)}
                      </Text>
                    </Flex>
                  </Group>
                );
              })}
            </Flex>
          </Flex>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: theme.colors.cyan[4],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
