import { Avatar, Button, Checkbox, CloseButton, Divider, Flex, Group, Paper, Select, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconChevronDown, IconPhoto } from "@tabler/icons-react";
import React from "react";
import useSWR from "swr";
import { GoodsApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { currencyFormat, tugrug } from "../../utils";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { ColumnType, Table } from "../table";

type Props = {
  setFieldValue?: any;
  setAction?: any;
  values?: any;
};

export function BuyerCustomerDynamicPackageForm({ setFieldValue, setAction, values }: Props) {
  const [selected, setSelected] = React.useState<string[]>(values?.lines || []);
  const [categoryType, setCategoryType] = React.useState("");
  const [debouncedCategoryType] = useDebouncedValue(categoryType, 600);

  const { data: categoryData } = useSWR(`/erp/order/category.${categoryType}`, async () => {
    try {
      let res = await GoodsApi.category({ orderType: "PURCHASE", businessId: values?.supplierId });
      return res;
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
  });

  const columns = useHeader({
    selected,
    onClick: (key, row) => {
      if (key === "select_all") {
        setSelected(row.map((item: any) => ({ ...item, quantity: 1 })));
      }
      if (key === "unselect_all") {
        setSelected([]);
      }
      if (key === "select") {
        if (selected.some((item: any) => item.id === row.id)) {
          setSelected(selected.filter((item: any) => item.id !== row.id));
        } else {
          setSelected([...selected, { ...row, quantity: 1 }]);
        }
      }
    },
  });

  return (
    <Paper>
      <PageLayout
        title="Багцаар сонгох"
        subTitle="Багцаар сонгох"
        extra={[<CloseButton onClick={() => setAction([])} type="submit" key={"close"}></CloseButton>]}>
        <Divider color="gray" my="xs" />
        <Flex direction="row" justify="space-between">
          <Select
            data={[{ id: "", name: "Бүгд" }, ...(categoryData || [])].map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
            placeholder="сонгох"
            leftSectionWidth={200}
            onChange={(e: string | null) => {
              if (e) setCategoryType(e);
            }}
            value={categoryType}
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            rightSection={<IconChevronDown size="1rem" stroke={1} />}
            clearable
            leftSection={
              <Group w={"calc(100% - 20px)"} align="apart">
                <Text size="sm" fw={450} c="#44566C">
                  {"Категори шүүх"}
                </Text>
                <Text>:</Text>
              </Group>
            }
          />
          <Text size="sm" fw={500} c="#44566C" mt="md">{`Сонгосон бараа ${selected.length} байна.`}</Text>
        </Flex>
        <Divider mt="xs" />
        <Table
          filters={{
            query: "",
            businessId: values?.supplierId,
            orderType: "PURCHASE",
            // supplierId: "",
            categoryId: debouncedCategoryType,
            brandId: "",
          }}
          columns={columns}
          name="invoice.itemApi.variant.package.list"
          loadData={(data: any) => GoodsApi.variant(data!)}
        />
        <Divider color="gray" my="xs" />
        <Flex mt={20} direction="row" justify="space-between">
          <Button variant="default" onClick={() => setAction([])}>
            Буцах
          </Button>
          <Button
            onClick={() => {
              setFieldValue("lines", selected);
              setAction([]);
            }}>
            Хадгалах
          </Button>
        </Flex>
      </PageLayout>
    </Paper>
  );
}

const useHeader = ({ selected, onClick }: { selected: any; onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    renderCheck(record, index) {
      return (
        <>
          <Checkbox
            onChange={() => {}}
            onClick={() => {
              if (Array.isArray((record || []).rows) && Array.isArray(selected) && (record?.rows || []).length === (selected || []).length) {
                onClick("unselect_all", (record || []).rows);
              } else {
                onClick("select_all", (record || []).rows);
              }
            }}
            checked={(selected || []).length === (record?.rows || []).length}
          />
        </>
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={(selected || []).some((item: any) => item.id === record.id)}
        />
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "80px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "item.image",
    render: (record) => (
      <Avatar size={40} src={record?.image !== null ? record?.image : noimage} radius={5}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Kод",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w="max-content">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w="max-content">
          {record?.skuCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа нэр, хувилбарын нэр",
    sorter: true,
    dataIndex: "category",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.nameMon || "-"}{" "}
          {record?.optionValues.length > 0 ? record.optionValues.map((op: any, index: number) => <span key={index}>{" - " + op.name}</span>) : ""}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.unit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "price",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {tugrug(record?.price || 0)}
        </Text>
      );
    },
  },
  {
    title: "НӨАТ",
    dataIndex: "hasVat",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
          {currencyFormat(record?.vatAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "НХАТ",
    dataIndex: "hasTax",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w={"max-content"}>
          {currencyFormat(record?.taxAmount || 0)}
        </Text>
      );
    },
  },
];
