import { ActionIcon, Badge, Checkbox, Group, LoadingOverlay, Select, Table, Text, Tooltip } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconTruckDelivery } from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { DeliveryApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IDelivery } from "../../interfaces/IDelivery";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { CustomsTable } from "../custom-table";
import { Form } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { SelectField } from "../form/select-field";
import { ITableRef } from "../table";

const schema = yup.object({
  selection: yup.array().when("selected", (selected, schema) => {
    return selected
      ? schema.of(
          yup.object().shape({
            startDate: yup.string().required("Заавал бөглөнө!").nullable().optional(),
            staffId: yup.string().required("Заавал бөглөнө!").nullable().optional(),
            deliveryDate: yup.string().required("Заавал бөглөнө!").nullable().optional(),
            loadingDate: yup.string().required("Заавал бөглөнө!").nullable().optional(),
          }),
        )
      : schema.notRequired().optional().nullable();
  }),
});

export type IFormData = {
  id: string;
  startDate: string;
  staffId: string;
  deliveryDate: string;
  loadingDate: string;
  selected: boolean;
};

const initialFormData: IFormData = {
  id: "",
  startDate: "",
  staffId: "",
  deliveryDate: "",
  loadingDate: "",
  selected: false,
};

export function SupplierDeliveryDistributeList({ onSuccess, listRef }: { onSuccess?: () => void; listRef: any }) {
  const { classes } = useStyles();
  const nowMoment = new Date();
  const tableRef = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { deliveryNoteStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [formData] = React.useState<{ selection: IFormData[] }>({
    selection: [],
  });
  const [tableData, setTableData] = React.useState<{ count: number; rows: IDelivery[] }>({
    count: 0,
    rows: [],
  });

  const { data: staffData } = useSwr(
    `/api/ord/delivery_note/staff_select`,
    async () => {
      if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0) {
        let res = await DeliveryApi.staffSelect({ query: "", limit: 20 });
        return res;
      }
    },
    {},
  );

  const onSubmit = async (values: { selection: IFormData[] }) => {
    const requestData = (values.selection || []).filter((a: any) => a.selected);

    function hasEmptyObj(obj: any) {
      return Object.values(obj).some((value) => !value || value === "");
    }

    const check = (requestData || []).map((f) => hasEmptyObj(f));
    const reds = check.includes(true);

    if (requestData.length === 0) {
      message.error("Та хүргэлтийн захиалга сонгоно уу!");
      return;
    }

    if (reds) {
      message.error("Та хүргэлтийн захиалга сонгоно уу!");
    } else {
      try {
        await DeliveryApi.assign(
          requestData.map(
            (a) =>
              ({
                id: a.id,
                startDate: dayjs(a?.startDate).format(),
                staffId: a.staffId,
                deliveryDate: dayjs(a?.deliveryDate).format(),
                loadingDate: dayjs(a?.loadingDate).format(),
              } as IDelivery),
          ),
        );
        tableRef.current?.reload();
        message.success("Таны хүсэлт амжилттай!");
        onSuccess && onSuccess();
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }

    setLoading(false);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Form ref={listRef} validationSchema={schema} onSubmit={onSubmit} initialValues={formData}>
      {({ values, setFieldValue }) => {
        const selection: string[] = (values.selection || []).filter((a: any) => a.selected).map((a: IFormData) => a.id);

        return (
          <div className={classes.container}>
            <CustomsTable
              onResult={(thisTableData: { count: number; rows: IDelivery[] }) => {
                setTableData(thisTableData);
                listRef?.current?.setFormData((state: any) => ({
                  ...state,
                  selection: thisTableData.rows.map((item) => ({
                    ...initialFormData,
                    id: item.id,
                  })),
                }));
              }}
              ref={tableRef}
              thead={
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>#</Table.Th>
                    <Table.Th style={{ width: 40 }}>
                      <Checkbox
                        onChange={() => {
                          setFieldValue(
                            "selection",
                            tableData.rows.map((item) => ({
                              ...initialFormData,
                              id: item.id,
                              selected: selection.length !== tableData.rows.length,
                            })),
                          );
                        }}
                        checked={selection.length === tableData.rows.length}
                        indeterminate={selection.length > 0 && selection.length !== tableData.rows.length}
                      />
                    </Table.Th>
                    <Table.Th className={classes.th}>Үйлдэл</Table.Th>
                    <Table.Th className={classes.th}>Хүргэлт</Table.Th>
                    <Table.Th className={classes.th}>Худалдан авагч</Table.Th>
                    <Table.Th className={classes.th}>Худалдан авагч бизнес</Table.Th>
                    <Table.Th className={classes.th}>Хүлээн авах салбар</Table.Th>
                    {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
                      <Table.Th className={classes.th}>Хүргэлт хариуцсан</Table.Th>
                    )}
                    <Table.Th className={classes.th}>Ачилт хийх огноо</Table.Th>
                    <Table.Th className={classes.th}>Хүргэх огноо</Table.Th>
                    <Table.Th className={classes.th}>Хүлээлгэж өгөх огноо</Table.Th>
                    <Table.Th className={classes.th}>Бүсчлэл</Table.Th>
                    <Table.Th className={classes.th}>Чиглэл</Table.Th>
                  </Table.Tr>
                  {tableData.count !== 0 && (
                    <Table.Tr key="empty-row">
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
                        <Table.Th>
                          {tableData.count !== 0 && (
                            <Select
                              size="xs"
                              clearable
                              onChange={(value) => {
                                setFieldValue(
                                  "selection",
                                  (values.selection || []).map((a: IFormData) => ({ ...a, staffId: a.selected ? value : a.staffId })),
                                );
                              }}
                              label=""
                              placeholder="сонгох"
                              data={(staffData || [])?.map((item: any) => {
                                return {
                                  value: item?.id,
                                  label: item?.firstName,
                                };
                              })}
                              disabled={tableData?.count === 0}
                            />
                          )}
                        </Table.Th>
                      )}
                      <Table.Th>
                        {tableData.count !== 0 && (
                          <DatePickerInput
                            size="xs"
                            onChange={(value) => {
                              setFieldValue(
                                "selection",
                                (values.selection || []).map((a: IFormData) => ({ ...a, deliveryDate: a.selected ? value?.toISOString() : a.deliveryDate })),
                              );
                            }}
                            valueFormat="MM-DD-YYYY"
                            label=""
                            placeholder="өдөр сонгох"
                            disabled={tableData?.count === 0}
                            minDate={nowMoment}
                          />
                        )}
                      </Table.Th>
                      <Table.Th>
                        {tableData.count !== 0 && (
                          <DatePickerInput
                            size="xs"
                            onChange={(value) => {
                              setFieldValue(
                                "selection",
                                (values.selection || []).map((a: IFormData) => ({ ...a, startDate: a.selected ? value?.toISOString() : a.startDate })),
                              );
                            }}
                            valueFormat="MM-DD-YYYY"
                            label=""
                            placeholder="өдөр сонгох"
                            disabled={tableData?.count === 0}
                          />
                        )}
                      </Table.Th>
                      <Table.Th>
                        {tableData.count !== 0 && (
                          <DatePickerInput
                            size="xs"
                            onChange={(value) => {
                              setFieldValue(
                                "selection",
                                (values.selection || []).map((a: IFormData) => ({ ...a, loadingDate: a.selected ? value?.toISOString() : a.loadingDate })),
                              );
                            }}
                            valueFormat="MM-DD-YYYY"
                            label=""
                            placeholder="өдөр сонгох"
                            disabled={tableData?.count === 0}
                          />
                        )}
                      </Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                    </Table.Tr>
                  )}
                </Table.Thead>
              }
              tbody={(rows: IDelivery[]) => {
                return (
                  <Table.Tbody>
                    {(rows || []).map((record, index) => {
                      return (
                        <Table.Tr key={record.id}>
                          <Table.Td>
                            <Text size="sm" c="#44566C">
                              {index + 1}
                            </Text>
                          </Table.Td>
                          <Table.Td>
                            <Checkbox
                              checked={selection.includes(record.id)}
                              onChange={() => {
                                setFieldValue(`selection[${index}]`, {
                                  ...values.selection[index],
                                  id: record.id,
                                  selected: !selection.includes(record.id),
                                });
                              }}
                            />
                          </Table.Td>
                          <Table.Td>
                            <Tooltip label="Хуваарилах" position="bottom">
                              <ActionIcon
                                variant="light"
                                radius={100}
                                w={35}
                                h={35}
                                p={0}
                                disabled={selection.indexOf(record.id) === -1}
                                onClick={(event) => {
                                  event.preventDefault();
                                  const selectedRow = values.selection.find((row: any) => row.id === record.id);
                                  if (selectedRow) {
                                    const singleRowData = { selection: [selectedRow] };
                                    onSubmit(singleRowData);
                                  } else {
                                    message.error("Row data not found!");
                                  }
                                }}>
                                <IconTruckDelivery />
                              </ActionIcon>
                            </Tooltip>
                          </Table.Td>
                          <Table.Td>
                            <Badge
                              size="md"
                              radius="sm"
                              variant="outline"
                              style={{
                                border: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNoteStatus)?.color} 1px solid`,
                                color: `${deliveryNoteStatus.find((c: any) => c.code === record.deliveryNoteStatus)?.color}`,
                              }}
                              styles={{
                                label: {
                                  overflow: "visible",
                                },
                              }}>
                              {deliveryNoteStatus.find((c: any) => c?.code === record?.deliveryNoteStatus)?.name || "-"}
                            </Badge>
                          </Table.Td>
                          <Table.Td>
                            <div>
                              <Text size="sm" c="#44566C" w="max-content">
                                {record?.receiverBusiness?.partner?.businessName ?? "-"}
                              </Text>
                              <Text size="sm" c="cyan" w="max-content">
                                {record?.receiverBusiness?.partner?.refCode ?? "-"}
                              </Text>
                            </div>
                          </Table.Td>
                          <Table.Td>
                            <div>
                              <Text size="sm" c="#44566C" w="max-content">
                                {record?.receiverBusiness?.profileName ?? "-"}
                              </Text>
                              <Text size="sm" c="cyan" w="max-content">
                                {record?.receiverBusiness?.refCode ?? "-"}
                              </Text>
                            </div>
                          </Table.Td>
                          <Table.Td>
                            <Text size="sm" c="#44566C" w="max-content">
                              {record?.receiverBranch?.refCode ?? "-"}, {record?.receiverBranch?.name ?? "-"}
                            </Text>
                          </Table.Td>

                          {(user?.permissions ?? []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
                            <Table.Td>
                              {selection.indexOf(record.id) !== -1 ? (
                                <SelectField
                                  size="xs"
                                  name={`selection[${index}].staffId`}
                                  error={values.selection[index].selected && !values.selection[index].staffId ? "Заавал бөглөх!" : ""}
                                  label=""
                                  placeholder="сонгох"
                                  options={(staffData || [])?.map((item: any) => {
                                    return {
                                      value: item?.id,
                                      label: item?.firstName,
                                    };
                                  })}
                                />
                              ) : (
                                <Text size="sm" c="#44566C" w="max-content">
                                  {dateTimeSecFormat(record?.staffId) ?? "-"}
                                </Text>
                              )}
                            </Table.Td>
                          )}
                          <Table.Td>
                            <Group align="center">
                              {selection.indexOf(record.id) !== -1 ? (
                                <DatePickerField
                                  error={values.selection[index].selected && !values.selection[index].deliveryDate ? "Заавал бөглөх!" : ""}
                                  size="xs"
                                  name={`selection[${index}].deliveryDate`}
                                  label=""
                                  placeholder="өдөр сонгох"
                                  minDate={nowMoment}
                                />
                              ) : (
                                <Text size="sm" c="#44566C" w="max-content">
                                  {dateTimeSecFormat(record?.deliveryDate) ?? "-"}
                                </Text>
                              )}
                            </Group>
                          </Table.Td>
                          <Table.Td>
                            {selection.indexOf(record.id) !== -1 ? (
                              <DatePickerField
                                size="xs"
                                name={`selection[${index}].startDate`}
                                error={values.selection[index].selected && !values.selection[index].startDate ? "Заавал бөглөх!" : ""}
                                label=""
                                placeholder="өдөр сонгох"
                              />
                            ) : (
                              <Text size="sm" c="#44566C" w="max-content">
                                {dateTimeSecFormat(record?.startDate) ?? "-"}
                              </Text>
                            )}
                          </Table.Td>
                          <Table.Td>
                            <Group align="center">
                              {selection.indexOf(record.id) !== -1 ? (
                                <DatePickerField
                                  size="xs"
                                  name={`selection[${index}].loadingDate`}
                                  error={values.selection[index].selected && !values.selection[index].loadingDate ? "Заавал бөглөх!" : ""}
                                  label=""
                                  placeholder="өдөр сонгох"
                                />
                              ) : (
                                <Text size="sm" c="#44566C" w="max-content">
                                  {dateTimeSecFormat(record?.loadingDate) ?? "-"}
                                </Text>
                              )}
                            </Group>
                          </Table.Td>
                          <Table.Td>
                            <Text size="sm" c="#44566C" w="max-content">
                              {record?.region?.name ?? "-"}
                            </Text>
                          </Table.Td>
                          <Table.Td>
                            <Text size="sm" c="#44566C" w="max-content">
                              {record?.direction?.name ?? "-"}
                            </Text>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                );
              }}
              name="supplier-delivery-distribute.list"
              filters={{
                deliveryNoteStatus: "DRAFT",
                businessId: "",
                staffId: "",
                startDate: "",
                endDate: "",
                paymentStatus: "",
                paymentTermId: "",
              }}
              loadData={(filterData) => DeliveryApi.list(filterData!)}
            />
          </div>
        );
      }}
    </Form>
  );
}
const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },

  th: {
    whiteSpace: "nowrap",
    fontWeight: "500",
  },
}));
